<template>
  <effects-summary />
  <loading v-if="loading" />
  <error v-else-if="error" />
</template>

<script>
import { defineComponent, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import EffectsSummary from "@/components/summaries/EffectsSummary.vue";
import Error from "@/components/Error.vue";
import Loading from "@/components/Loading.vue";

export default defineComponent({
  name: "EffectsTab",
  components: {
    EffectsSummary,
    Error,
    Loading,
  },
  props: {
    account: Object,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    store.dispatch("ledgers/fetchLedgerEffects", route.params.ledger_sequence);
    const loading = computed(
      () => store.getters["ledgers/getLoadingTabStatus"]
    );
    const error = computed(() => store.getters["ledgers/getError"]);

    const scroll = () => {
      window.onscroll = () => {
        let bottomOfWindow =
          window.innerHeight + window.pageYOffset + 1 >=
          document.body.scrollHeight;

        if (bottomOfWindow && !loading.value) {
          store.dispatch("ledgers/fetchMoreLedgerEffects", route.params.ledger_sequence);
        }
      };
    };
    onMounted(scroll);
    onUnmounted(() => {
      window.onscroll = null;
    });
    return { loading, error };
  },
});
</script>
