<template>
  <operation-summary />
  <loading v-if="loading" />
  <error v-else-if="error" />
</template>

<script>
import { watch, defineComponent, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import OperationSummary from "@/components/summaries/OperationSummary.vue";
import { useRoute } from "vue-router";
import Error from "@/components/Error.vue";
import Loading from "@/components/Loading.vue";
export default defineComponent({
  name: "OperationsTab",
  components: {
    OperationSummary,
    Loading,
    Error,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    store.dispatch("ledgers/fetchLedgerOperations", route.params.ledger_sequence);

    const loading = computed(
      () => store.getters["ledgers/getLoadingTabStatus"]
    );
    const error = computed(() => store.getters["ledgers/getError"]);

    const scroll = () => {
      window.onscroll = () => {
        let bottomOfWindow =
          window.innerHeight + window.pageYOffset + 1 >=
          document.body.scrollHeight;

        if (bottomOfWindow && !loading.value) {
          store.dispatch(
            "ledgers/fetchMoreLedgerOperations",
            route.params.ledger_sequence
          );
        }
      };
    };
    onMounted(scroll);
    onUnmounted(() => {
      window.onscroll = null;
    });

    return { loading, error };
  },
});
</script>
