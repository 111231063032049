<template>
  <template v-if="payments && payments.length">
    <template v-for="(payment, idx) in payments" :key="idx">
       <payment-info :operation-details="payment" :idx="idx" />
    </template>
  </template>
  <template v-if="payments && payments.length == 0 && !loading">
    <no-content />
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { formatDate, formatDateElapsedTime } from "@/utils/date.js";
import PaymentInfo from "@/components/operations/PaymentInfo.vue";
import NoContent from "@/components/NoContent.vue";

export default defineComponent({
  name: "PaymentsSummary",
  components: {
    PaymentInfo,
    NoContent,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const payments = computed(() => {
      switch (route.name) {
        case "AccountDetailsRoute":
          return store.getters["accounts/getAccount"].payments_r;
        case "LedgerDetailsRoute":
          return store.getters["ledgers/getLedgerPayments"];
        default:
          return store.getters["payments/getLatestPayments"];
      }
    });

    const accountLabels = computed(() => store.getters["accounts/getLabels"]);

    const loading = computed(() => {
      switch (route.name) {
        case "LedgerDetailsRoute":
          return store.getters["ledgers/getLoadingTabStatus"];
        default:
          return store.getters["payments/getLoadingStatus"];
      }
    });
    return {
      store,
      payments,
      accountLabels,
      formatDate,
      formatDateElapsedTime,
      loading,
    };
  },
});
</script>
