<template>
  <div class="d-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>
    <div class="ms-2">
      <div style="min-width: 88px" class="d-flex justify-content-between">
        <span
          v-tooltip
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('ledger_details.prev_ledger')"
        >
          <button
            class="btn bg-white text-primary border"
            @click="prevLedger()"
          >
            <i class="bi bi-arrow-left-circle"></i></button
        ></span>
        <span
          v-tooltip
          class="d-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('ledger_details.next_ledger')"
        >
          <button
            class="btn bg-white text-primary border"
            @click="nextLedger()"
          >
            <i class="bi bi-arrow-right-circle"></i></button
        ></span>
      </div>
    </div>
  </div>
  <ledger-stats />
  <div class="btn-group w-100 d-flex flex-row" role="group">
    <div
      v-for="(tab, idx) in navTabs"
      :key="idx"
      :class="`btn btn-link border text-decoration-none w-100 bg-body
      ${currTab === tab.name ? 'text-primary' : 'text-reset'}`"
      @click="onTabChange(tab)"
    >
      <i :class="`bi ${tab.icon}`"></i>
      <span class="d-none d-xl-inline d-xxl-inline ms-1">
        {{ $t(`account_details.tabs.${tab.name}`) }}
      </span>
    </div>
  </div>
  <div class="mt-2">
    <component :is="`${currTab}-tab`" v-if="currTab === 'assets'" />
    <component :is="`${currTab}-tab`" v-else />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { formatDate } from "@/utils/date";
import Search from "@/components/Search.vue";
import LedgerStats from "@/components/stats/LedgerStats.vue";
import TxnSummary from "@/components/summaries/TxnSummary.vue";
import TransactionsTab from "@/components/ledgers/TransactionsTab.vue";
import OperationsTab from "@/components/ledgers/OperationsTab.vue";
import EffectsTab from "@/components/ledgers/EffectsTab.vue";
import PaymentsTab from "@/components/ledgers/PaymentsTab.vue";
import Error from "@/components/Error.vue";
import Loading from "@/components/Loading.vue";

export default defineComponent({
  name: "LedgersDetails",
  components: {
    Search,
    LedgerStats,
    TxnSummary,
    Error,
    Loading,
    TransactionsTab,
    OperationsTab,
    EffectsTab,
    PaymentsTab,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const currTab = ref("transactions");
    store.dispatch("ledgers/fetchLedgerBySeq", route.params.ledger_sequence);

    const nextLedger = () => {
      router.replace({
        name: "LedgerDetailsRoute",
        params: { ledger_sequence: parseInt(route.params.ledger_sequence) + 1 },
      });
    };

    const prevLedger = () => {
      router.replace({
        name: "LedgerDetailsRoute",
        params: { ledger_sequence: parseInt(route.params.ledger_sequence) - 1 },
      });
    };
    const scroll = () => {
      window.onscroll = () => {
        let bottomOfWindow =
          window.innerHeight + window.pageYOffset + 1 >=
          document.body.scrollHeight;

        if (bottomOfWindow) {
          store.dispatch("ledgers/fetchMoreTxnsForLedger");
        }
      };
    };
    onMounted(scroll);
    onUnmounted(() => {
      window.onscroll = null;
    });

    const onTabChange = (tab) => {
      currTab.value = tab.name;
    };

    const navTabs = [
      { name: "transactions", icon: "bi-list-ul" },
      { name: "payments", icon: "bi-arrow-left-right" },
      { name: "operations", icon: "bi-view-list" },
      { name: "effects", icon: "bi-clipboard-check" },
    ];

    return {
      nextLedger,
      prevLedger,
      currTab,
      onTabChange,
      formatDate,
      navTabs,
    };
  },
});
</script>
