
<script>
import { defineComponent, h, computed } from "vue";
import { useStore } from "vuex";
import CreateAccount from "./types/CreateAccount.vue";
import Payment from "./types/Payment.vue";
import PathPaymentStrictReceive from "./types/PathPaymentStrictReceive.vue";
import AccountMerge from "./types/AccountMerge.vue";
import PathPaymentStrictSend from "./types/PathPaymentStrictSend.vue";

export default defineComponent({
  name: "PaymentInfo",
  props: {
    operationDetails: Object,
    idx: Number,
    details: Boolean,
  },
  components() {},
  setup(props) {
    const store = useStore();
    const assetsMeta = computed(
      () => store.getters["assets/getAssetsMeta"].data
    );
    const xlmLogo = require("@/assets/images/assets/xlm.png");
    return () => {
      switch (props.operationDetails.type) {
        case "create_account":
          return h(CreateAccount, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            details: props.details,
          });
        case "payment":
          return h(Payment, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            xlmLogo,
            assetMeta:
              assetsMeta.value[
                props.operationDetails.asset_code +
                  "_" +
                  props.operationDetails.asset_issuer
              ],
            details: props.details,
          });
        case "path_payment_strict_receive":
          return h(PathPaymentStrictReceive, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            xlmLogo,
            sourceAssetMeta:
              assetsMeta.value[
                props.operationDetails.source_asset_code +
                  "_" +
                  props.operationDetails.source_asset_issuer
              ],
            assetMeta:
              assetsMeta.value[
                props.operationDetails.asset_code +
                  "_" +
                  props.operationDetails.asset_issuer
              ],
            details: props.details,
          });
        case "account_merge":
          return h(AccountMerge, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            details: props.details,
          });
        case "path_payment_strict_send":
          return h(PathPaymentStrictSend, {
            operationDetails: props.operationDetails,
            idx: props.idx,
            xlmLogo,
            sourceAssetMeta:
              assetsMeta.value[
                props.operationDetails.source_asset_code +
                  "_" +
                  props.operationDetails.source_asset_issuer
              ],
            assetMeta:
              assetsMeta.value[
                props.operationDetails.asset_code +
                  "_" +
                  props.operationDetails.asset_issuer
              ],
            details: props.details,
          });
        default:
          return [];
      }
    };
  },
});
</script>
