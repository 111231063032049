<template>
  <ledger-stats-skeleton v-if="loading" />
  <error v-else-if="error" />
  <template v-else>
    <div class="card mb-2">
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 ">
              <i class="bi bi-hash me-3"></i
              >{{ $t("ledger_stats.ledger_sequence") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span class="align-self-center small font-monospace fw-bold">{{
                ledgerDetails.sequence
              }}</span>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 ">
              <i class="bi bi-hash me-3"></i
              >{{ $t("ledger_stats.ledger_hash") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span class="align-self-center small font-monospace">{{
                ledgerDetails.hash
              }}</span
              ><router-link
                to="#"
                class="float-end text-decoration-none"
                @click="copy(ledgerDetails.hash)"
              >
                {{ $t("ledger_stats.copy") }}
              </router-link>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 ">
              <i class="bi bi-hash me-3"></i>{{ $t("ledger_stats.prev_hash") }}
            </div>
            <div class="w-75 py-2 px-3">
              <router-link
                class="
                  align-self-center
                  small
                  font-monospace
                  text-decoration-none
                "
                :to="{
                  name: 'LedgerDetailsRoute',
                  params: { ledger_sequence: ledgerDetails.sequence - 1 },
                }"
                >{{ ledgerDetails.prev_hash }}</router-link
              >
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 ">
              <i class="bi bi-clock me-3"></i>{{ $t("ledger_stats.closed_at") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span class="align-self-center small font-monospace">{{
                formatDate(ledgerDetails.closed_at) +
                " · " +
                formatDateElapsedTime(ledgerDetails.closed_at)
              }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="card mb-2">
      <div class="card-body">
        <div class="row g-2 small">
          <div class="col-lg-3">
            <div class="d-flex p-0 border-0">
              <div class="w-75 py-1 px-3 text-muted">
                {{ $t("ledger_stats.successful_txns") }}
              </div>
              <div
                class="
                  w-25
                  py-1
                  px-3
                  fw-bold
                  font-monospace
                  text-end text-success
                "
              >
                {{ ledgerDetails.successful_transaction_count }}
              </div>
            </div>
            <div class="d-flex p-0 border-0">
              <div class="w-75 py-1 px-3 text-muted">
                {{ $t("ledger_stats.failed_txns") }}
              </div>
              <div
                class="
                  w-25
                  py-1
                  px-3
                  fw-bold
                  font-monospace
                  text-end text-danger
                "
              >
                {{ ledgerDetails.failed_transaction_count }}
              </div>
            </div>
            <div class="d-flex p-0 border-0">
              <div class="w-75 py-1 px-3 text-muted">
                {{ $t("ledger_stats.total_transactions") }}
              </div>
              <div class="w-25 py-1 px-3 fw-bold font-monospace text-end">
                {{
                  ledgerDetails.successful_transaction_count +
                  ledgerDetails.failed_transaction_count
                }}
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="d-flex p-0 border-0">
              <div class="w-50 py-1 px-3 text-muted">
                {{ $t("ledger_stats.operations") }}
              </div>
              <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                {{
                  ledgerDetails.tx_set_operation_count +
                  " / " +
                  ledgerDetails.max_tx_set_size
                }}
              </div>
            </div>
            <div class="p-0 border-0 d-flex">
              <div class="py-2 px-3 align-self-center w-100">
                <div class="progress" style="height: 12px">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :style="`width: ${progress}%`"
                    :aria-valuenow="progress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <div class="d-flex p-0 border-0">
              <div class="w-75 py-1 px-3 text-muted">
                {{ $t("ledger_stats.ledger_closing_time") }}
              </div>
              <div class="w-25 py-1 px-3 fw-bold font-monospace text-end">
                4s
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="d-flex p-0 border-0">
              <div class="w-50 py-1 px-3 text-muted">
                {{ $t("ledger_stats.base_fee") }}
              </div>
              <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                {{
                  (0.00001 * ledgerDetails.base_fee_in_stroops).toFixed(4) +
                  $t("ledger_stats.xlm")
                }}
              </div>
            </div>
            <div class="d-flex p-0 border-0">
              <div class="w-50 py-1 px-3 text-muted">
                {{ $t("ledger_stats.fee_pool") }}
              </div>
              <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                {{
                  parseInt(ledgerDetails.fee_pool).toLocaleString() +
                  $t("ledger_stats.xlm")
                }}
              </div>
            </div>
            <div class="d-flex p-0 border-0">
              <div class="w-50 py-1 px-3 text-muted">
                {{ $t("ledger_stats.protocol_version") }}
              </div>
              <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                {{ ledgerDetails.protocol_version }}
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="d-flex p-0 border-0 list-group-item-action"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title=""
              :data-bs-original-title="$t('ledger_stats.agg_xlm_value')"
              v-tooltip
            >
              <div class="w-50 py-1 px-3 text-muted">
                {{ $t("ledger_stats.xlm") }}
              </div>
              <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                {{ checkLedgerValue("xlm_value") }}
              </div>
            </div>
            <div
              class="d-flex p-0 border-0 list-group-item-action"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title=""
              :data-bs-original-title="$t('ledger_stats.agg_btc_value')"
              v-tooltip
            >
              <div class="w-50 py-1 px-3 text-muted">
                {{ $t("ledger_stats.btc") }}
              </div>
              <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                {{ checkLedgerValue("btc_value") }}
              </div>
            </div>
            <div
              class="d-flex p-0 border-0 list-group-item-action"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title=""
              :data-bs-original-title="$t('ledger_stats.agg_usd_value')"
              v-tooltip
            >
              <div class="w-50 py-1 px-3 text-muted">
                {{ $t("ledger_stats.usd") }}
              </div>
              <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                {{ checkLedgerValue("usd_value") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { formatDate, formatDateElapsedTime } from "@/utils/date";
import Error from "@/components/Error.vue";
import LedgerStatsSkeleton from "@/components/skeletons/stats/LedgerStatsSkeleton.vue";
export default defineComponent({
  name: "LedgerStats",
  components: {
    Error,
    LedgerStatsSkeleton,
  },
  setup() {
    const store = useStore();
    const ledgerDetails = computed(
      () => store.getters["ledgers/getSelectedLedger"]
    );
    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };
    const progress = computed(
      () =>
        (ledgerDetails.value.tx_set_operation_count /
          ledgerDetails.value.max_tx_set_size) *
        100
    );
    const loading = computed(() => store.getters["ledgers/getLoadingStatus"]);
    const error = computed(() => store.getters["ledgers/getError"]);
    const ledgerValue = computed(
      () => store.getters["analytics/getLedgerValue"]
    );
    const checkLedgerValue = (value) => {
      if (
        !ledgerValue.value.loading &&
        !ledgerValue.value.error &&
        ledgerValue.value.data[value]
      ) {
        return ledgerValue.value.data[value].toLocaleString();
      } else {
        return "-";
      }
    };
    return {
      loading,
      error,
      ledgerDetails,
      formatDate,
      formatDateElapsedTime,
      progress,
      copy,
      ledgerValue,
      checkLedgerValue,
    };
  },
});
</script>
